<!-- Scan Qr Code by camera -->
<template>
    <v-container fluid>
        
        <h3><v-icon @click="goBackToPreviousPage">mdi-chevron-left</v-icon>Scan Qr Code</h3>
        <br>

        
          <!-- Phần đầu tiên: Full width text -->
          <v-card-text class="full-width-text">
            Place QR code inside the frame to scan
          </v-card-text>

          <div id="qr-reader-charger" width="100%"></div>

          <!-- Phần ba: Scanning Code -->
          <v-card-text class="full-width-text">
            Scanning Code
          </v-card-text>
       
        <v-row class="submit-btn-row">
            <v-col >
                <v-btn block class="submit-btn" color="primary" @click="getQrData">Place Camera Code</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import Vue from "vue";
import { Html5Qrcode } from "html5-qrcode";
export default Vue.extend({
    data() {
      return {
        decodedText: '',
      };
    },
    methods: {
        async initialize() {

            this.$nextTick(() => {
                // Code sẽ được thực thi sau khi DOM đã được cập nhật
                const qrReader = document.getElementById('qr-reader-charger');
                if (qrReader) {
                    let html5QrCode = this.html5QrCode;
                    if (!html5QrCode) {
                        html5QrCode = new Html5Qrcode("qr-reader-charger");
                        this.html5QrCode = html5QrCode;
                    }
                    const qrWith = screen.width * 60 / 100;
                    if (this.html5QrCode?.isScanning) {
                        this.html5QrCode.stop();
                    } else {
                        html5QrCode.start(
                        {
                            facingMode: "environment",
                        },
                        {
                            fps: 10,
                            qrbox: {width: qrWith, height: qrWith},
                        },
                        (decodedText) => {
                            this.decodedText = decodedText;
                        },
                        () => {}
                        );
                    }
                } else {
                    console.error('HTML Element with id=qr-reader-charger not found');
                }
            });
        },
        goBackToPreviousPage() {
            this.$router.push(`/app/my_charger/login_charger_m`);
            if (this.html5QrCode?.isScanning) {
                this.html5QrCode.stop();
            }
        },
        getQrData() {
            this.$router.push(`/app/my_charger/login_charger_m` + '?charger_code=' + this.decodedText);
            if (this.html5QrCode?.isScanning) {
                this.html5QrCode.stop();
            }
        },
    },
    async created() {
        await this.initialize();
    },
    html5QrCode: null,
  });
</script>

<style scoped>
  .h3 {
  margin-left: 8px; /* Khoảng cách giữa biểu tượng mũi tên và tiêu đề */
  display: inline;
}
.icon-title {
  display: inline-flex;
  align-items: center; /* Căn chỉnh các phần tử theo chiều dọc */
}


.submit-btn-row {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px; /* Khoảng cách từ lề dưới của màn hình */
  justify-content: space-around
}

.submit-btn {
  border: 1px solid; /* Thiết lập viền */
  border-radius: 4px; /* Định dạng các góc của nút */
}
.full-width-text {
  text-align: center;
  font-size: 1.2em; /* Cỡ chữ lớn hơn */
}
  </style>